// Generated by Framer (3f65431)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { onAppear } from "https://framerusercontent.com/modules/ztHeKdDYIwNs62qRUiAl/lkdNywPv8XWYaCZyqsXP/Scrambler.js";
const RichTextOnAppear = onAppear(RichText);

const serializationHash = "framer-8DSVa"

const variantClassNames = {HAZOGgb8W: "framer-v-18wmufw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, jNLzExfHL: click ?? props.jNLzExfHL} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jNLzExfHL, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "HAZOGgb8W", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapj4kd04 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (jNLzExfHL) {const res = await jNLzExfHL(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-18wmufw", className, classNames)} data-border data-framer-name={"primary"} data-highlight layoutDependency={layoutDependency} layoutId={"HAZOGgb8W"} onTap={onTapj4kd04} ref={ref ?? ref1} style={{"--border-bottom-width": "0.3px", "--border-color": "rgba(255, 255, 255, 0.6)", "--border-left-width": "0.3px", "--border-right-width": "0.3px", "--border-style": "solid", "--border-top-width": "0.3px", backgroundColor: "rgba(225, 225, 225, 0.3)", ...style}}><RichTextOnAppear __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dUIFByZXNzdXJhIFRyaWFsIEx0", "--framer-font-family": "\"GT Pressura Trial Lt\", \"GT Pressura Trial Lt Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-letter-spacing": "0.2em", "--framer-line-height": "1.6em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255)))"}}>TAP HERE TO REVEAL</motion.p></React.Fragment>} className={"framer-b0wrm5"} fonts={["CUSTOM;GT Pressura Trial Lt"]} layoutDependency={layoutDependency} layoutId={"RNThOhjKB"} style={{"--extracted-r6o4lv": "var(--token-b1dc2fec-7be9-4139-9683-cb1397d923a7, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8DSVa.framer-114ju5l, .framer-8DSVa .framer-114ju5l { display: block; }", ".framer-8DSVa.framer-18wmufw { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 20px 40px 20px 40px; position: relative; width: min-content; }", ".framer-8DSVa .framer-b0wrm5 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8DSVa.framer-18wmufw { gap: 0px; } .framer-8DSVa.framer-18wmufw > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-8DSVa.framer-18wmufw > :first-child { margin-left: 0px; } .framer-8DSVa.framer-18wmufw > :last-child { margin-right: 0px; } }", ".framer-8DSVa[data-border=\"true\"]::after, .framer-8DSVa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 62.5
 * @framerIntrinsicWidth 248.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"jNLzExfHL":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPnHVhSK9l: React.ComponentType<Props> = withCSS(Component, css, "framer-8DSVa") as typeof Component;
export default FramerPnHVhSK9l;

FramerPnHVhSK9l.displayName = "Button";

FramerPnHVhSK9l.defaultProps = {height: 62.5, width: 248.5};

addPropertyControls(FramerPnHVhSK9l, {jNLzExfHL: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerPnHVhSK9l, [{explicitInter: true, fonts: [{family: "GT Pressura Trial Lt", source: "custom", url: "https://framerusercontent.com/assets/PAG3pOUxhA1VaKdovU992bEVxA.woff2"}]}], {supportsExplicitInterCodegen: true})